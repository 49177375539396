<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.create_stamp") }}
      <v-spacer></v-spacer>
      <template v-if="info.email_goods_receipt">
        <a
          class="mr-1 v-btn v-btn--outlined theme--light v-size--small default"
          :href="templateLink"
          target="_blank"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.sample_file") }}
        </a>

        <v-btn
          color="primary"
          small
          outlined
          class="mr-1"
          @click="$refs.inputUploadFile.click()"
        >
          <v-icon>mdi-upload</v-icon>
          {{ $t("labels.upload") }}
        </v-btn>
        <input
          type="file"
          ref="inputUploadFile"
          accept=".xlsx"
          @change="onInputFileChange"
          class="d-none"
        />

        <v-btn
          color="success"
          small
          outlined
          class="mr-1"
          @click="create"
          :disabled="items.length < 1"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </template>
      <v-btn color="error" small outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div v-if="info.email_goods_receipt" class="font-italic warning--text">
        <v-row dense>
          <v-col cols="10">
            {{ $t("labels.stamp_send_to_email") }}:
            <b>{{ info.email_goods_receipt }}</b>
            <br />
            <span class="error--text">
              {{ $t("labels.create_stamp_note") }}
            </span>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              class="c-input-small input-disabled-bold mt-1"
              v-model="stamp_size"
              :items="sizeOptions"
              :label="$t('labels.stamp_size')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div v-else class="font-italic error--text">
        {{ $t("labels.create_stamp_no_email") }}
      </div>
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header" v-if="items && items.length > 0">
            <tr>
              <th>{{ $t("labels.warehouse_1") }}</th>
              <th>{{ $t("labels.barcode") }}</th>
              <th>{{ $t("labels.goods_name") }}</th>
              <th>{{ $t("labels.goods_description") }}</th>
              <th>{{ $t("labels.size") }}</th>
              <th>{{ $t("labels.element") }}</th>
              <th>{{ $t("labels.origin") }}</th>
              <th>{{ $t("labels.conformity_code") }}</th>
              <th>{{ $t("labels.quantity") }}</th>
              <th>{{ $t("labels.price") }}</th>
              <th>{{ $t("labels.expired_time") }}</th>
              <th>{{ $t("labels.supplier") }}</th>
              <th>{{ $t("labels.note") }}</th>
            </tr>
          </thead>
          <tbody v-if="items && items.length > 0">
            <tr v-for="(item, key) in items" :key="`re_${key}`">
              <td>
                <span
                  v-if="!item.warehouse_code"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}</span
                >
                <span v-else>{{ item.warehouse_code }}</span>
              </td>
              <td>
                <span
                  v-if="!item.customer_goods_barcode"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}
                </span>
                <span v-else>{{ item.customer_goods_barcode }}</span>
              </td>
              <td>
                <span v-if="!item.name" class="warning--text font-italic">
                  {{ $t("labels.not_yet") }}
                </span>
                <span v-else>{{ item.name }}</span>
              </td>
              <td>
                <span
                  v-if="!item.description"
                  class="warning--text font-italic"
                >
                  {{ $t("labels.not_yet") }}
                </span>
                <span v-else>{{ item.description }}</span>
              </td>
              <td>
                <span v-if="!item.size" class="warning--text font-italic">
                  {{ $t("labels.not_yet") }}
                </span>
                <span v-else>{{ item.size }}</span>
              </td>
              <td>
                <span v-if="!item.element" class="error--text font-italic">
                  {{ $t("labels.required") }}
                </span>
                <span v-else>{{ item.element }}</span>
              </td>
              <td>
                <span v-if="!item.origin" class="error--text font-italic">
                  {{ $t("labels.required") }}
                </span>
                <span v-else>{{ item.origin }}</span>
              </td>
              <td>
                <span
                  v-if="!item.conformity_code"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}
                </span>
                <span v-else>{{ item.conformity_code }}</span>
              </td>
              <td>
                <span
                  v-if="!item.quantity || item.quantity == 0"
                  class="error--text font-italic"
                >
                  {{ $t("labels.required") }}
                </span>
                <span v-else>{{ item.quantity }}</span>
              </td>
              <td>
                <span
                  v-if="!item.price && item.price != 0"
                  class="warning--text font-italic"
                >
                  {{ $t("labels.not_yet") }}
                </span>
                <span v-else>{{ formatNumber(item.price) }}</span>
              </td>
              <td>
                <span
                  v-if="!item.expired_time"
                  class="warning--text font-italic"
                >
                  {{ $t("labels.not_yet") }}
                </span>
                <span v-else>{{ item.expired_time }}</span>
              </td>
              <td>
                <span v-if="!item.supplier" class="error--text font-italic">
                  {{ $t("labels.required") }}
                </span>
                <span v-else>{{ item.supplier }}</span>
              </td>
              <td>
                <span>{{ item.note }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";

export default {
  name: "GenerateStamp",
  components: {},
  props: {},
  data: () => ({
    items: [],
    isLoading: false,
    info: {},
    stamp_size: "50x40",
    sizeOptions: [
      {
        text: "50x40",
        value: "50x40",
      },
      {
        text: "75x50",
        value: "75x50",
      },
    ],
  }),
  computed: {
    itemGroupWarehouse() {
      const group = {};
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const key = `${item.warehouse_code}@${item.customer_goods_barcode}@${item.supplier}`;
        if (!group[key]) {
          group[key] = [];
        }
        group[key].push(item);
      }
      return group;
    },
    templateLink() {
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_cap_tem.xlsx`;
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      httpClient.post("/company-info").then(({ data }) => {
        this.info = { ...data };
      });
    },
    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-hang-hoa-cap-tem.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-list-export-for-generate-stamp",
          {},
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async onInputFileChange(e) {
      const mappingFields = {
        KhoWarehouse: "warehouse_code",
        Barcode: "customer_goods_barcode",
        "Số lượngQuantity": "quantity",
        "HSDExp. Date": "expired_time",
        "Nhà cung cấpSupplier": "supplier",
        "Ghi chúNote": "note",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            const mapkey = i.replace(/\r?\n|\r/g, "");
            item[mappingFields[mapkey]] = `${v[i]}`.trim();
          });
          return item;
        });
        this.getGoods(items);
      };
      await reader.readAsArrayBuffer(f);
    },

    isValidData() {
      const items = [...this.items];
      const emptyQuantity = items
        .filter(
          (item) =>
            !item.warehouse_code ||
            !item.customer_goods_barcode ||
            !item.quantity ||
            item.quantity == "0" ||
            !item.element ||
            !item.origin ||
            !item.supplier ||
            !item.conformity_code
        )
        .map((item) => item.customer_goods_barcode);

      if (emptyQuantity && emptyQuantity.length > 0) {
        this.$vToastify.error(
          this.$t("messages.data_goods_missing", {
            barcodes: emptyQuantity.join("; "),
          })
        );
        return false;
      }

      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find(
          (n) => n === `${i.warehouse_code}: ${i.customer_goods_barcode}`
        );
        if (!nd) {
          noDuplicateBarcodes.push(
            `${i.warehouse_code}: ${i.customer_goods_barcode}`
          );
        } else {
          duplicateBarcodes.push(
            `${i.warehouse_code}: ${i.customer_goods_barcode}`
          );
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      return true;
    },

    async getGoods(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
        return false;
      }

      if (items.length > 1000) {
        this.$vToastify.error(this.$t("messages.create_receipt_max_1000_rows"));
        return false;
      }

      const barcodes = [...items].map((i) => i.customer_goods_barcode);
      const { data } = await httpClient.post(
        "/get-goods-by-barcodes-for-create-stamp",
        { barcodes }
      );
      this.items = [...items].map((i) => {
        const item = [...data].find(
          (d) => d.customer_goods_barcode == i.customer_goods_barcode
        );
        if (item) {
          return { ...i, ...item };
        }
        return i;
      });
    },

    closeDialog() {
      this.$emit("close", true);
    },

    async create() {
      const isValid = this.isValidData();
      if (!isValid) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-receipt-create", {
          groups: this.itemGroupWarehouse,
          generate_stamp: true,
          stamp_size: this.stamp_size,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.items = [];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
